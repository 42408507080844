import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const APIURL = process.env.NEXT_PUBLIC_API_URL

export async function getCustomerProfile(token: string) {
  if (!token) return null
  return fetchSettings(token)
}

async function fetchSettings(token: string) {
  const response = await fetch(APIURL + '/settings', {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  })
  return await response.json()
}
