'use client'

import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Badge } from '@/components/ui/badge'
import { Check } from 'lucide-react'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { useRouter } from 'next/navigation'
import { gtag } from '@/lib/gtag'

type ProductProps = {
  id: string
  name: string
  price: string
  period: string
  description: string
  features: string[]
  cta: string
  popular: boolean
  freeTrial: boolean
  link: string
}

const plans: ProductProps[] = [
  {
    id: '1',
    name: 'Personal',
    price: '$2',
    period: '/month',
    description: 'Pay only for what you use',
    features: [
      'Dedicated 24/7 local AI contact center number*',
      'Add global access numbers for $2 each*',
      'Hides your own number',
      'Custom instructions for your personal agent',
      '$0.20 per screened call',
      'Up to 5 languages under one number',
      'Standard call rates',
      'Call transcripts',
      'Customer Portal',
      'Email support',
    ],
    cta: 'Get Started',
    popular: false,
    freeTrial: true,
    link: '/onboarding?plan=individual',
  },
  {
    id: '2',
    name: 'Team',
    price: '$5',
    period: '/user/month',
    description: 'Ideal for busy executives and teams',
    features: [
      'Dedicated 24/7 local AI contact center number*',
      'Add more global access numbers for $2 each*',
      'Hides your own number(s)',
      'Team Receptionist',
      'Able to disambiguate between multiple users',
      '50 screens per month included',
      '$0.10 per screened call overage',
      'All supported assistant languages',
      'Lowest call rates',
      'Customer Portal',
      'Unlimited history',
      'Search call transcripts',
      'Email support',
    ],
    cta: 'Get Started',
    popular: true,
    freeTrial: false,
    link: '/onboarding?plan=team',
  },
  {
    id: '3',
    name: 'Ultimate',
    price: '$25',
    period: '/user/month',
    description: 'A trustworthy team member for your SME',
    features: [
      'Dedicated 24/7 local AI contact center number*',
      'Add more global access numbers*',
      'Hides your own number(s)',
      'Team Receptionist',
      'Able to disambiguate between multiple users',
      'Enterprise Connectivity',
      'Volume discounts on numbers',
      'Lowest call rates',
      'Unlimited included screened calls',
      'All supported languages',
      'Customer Portal',
      'Unlimited history',
      'Search call transcripts',
      'VIP support',
    ],
    cta: 'Get Started',
    popular: false,
    freeTrial: false,
    link: '/onboarding?plan=enterprise',
  },
]
type PricingTableProps = {
  state: {
    signedIn: boolean
  }
}

export function PricingTable({ state }: PricingTableProps) {
  const [loading, setLoading] = useState(false)
  const router = useRouter()

  const selectProduct = async (plan: ProductProps) => {
    // Track plan selection
    gtag('event', 'select_plan', {
      event_category: 'pricing',
      event_label: plan.name,
      plan_id: plan.id,
      plan_price: plan.price,
    })

    if (!state.signedIn) {
      gtag('event', 'begin_signup', {
        event_category: 'conversion',
        event_label: plan.name,
      })

      window.location.href = plan.link
      return
    }

    setLoading(true)

    gtag('event', 'begin_onboarding', {
      event_category: 'conversion',
      event_label: plan.name,
      plan_id: plan.id,
    })

    router.push(plan.link)
    setLoading(false)
  }
  return (
    // Add bg-white for light mode and dark:bg-gray-900 for dark mode (adjust if needed)
    <div className="py-16 bg-transparent" id="pricing">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl font-bold text-center mb-12"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Choose Your Plan
        </motion.h2>
        <div className="grid md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <motion.div
              key={index}
              className="h-full"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Card
                className={`flex flex-col h-full ${plan.popular ? 'border-purple-500 dark:border-purple-400 border-2 relative overflow-hidden' : ''}`}
              >
                {plan.popular && (
                  <div className="absolute inset-0 z-0">
                    <div className="absolute inset-0 bg-purple-200 dark:bg-purple-800 opacity-20"></div>
                    <div className="absolute inset-0 animate-pulse-shine"></div>
                  </div>
                )}
                <CardHeader className="relative z-10">
                  <div className="flex justify-between items-start">
                    <div>
                      <CardTitle className="text-2xl font-bold">
                        {plan.name}
                      </CardTitle>
                      <div className="flex items-center">
                        <CardDescription className="text-xl font-semibold mt-2">
                          {plan.price}
                          <span className="text-sm font-normal">
                            {plan.period}
                          </span>
                          <br />
                          <span className="text-sm font-normal">
                            + applicable tax
                          </span>
                        </CardDescription>
                        {plan.freeTrial && (
                          <Badge variant="outline" className="ml-2">
                            free trial
                          </Badge>
                        )}
                      </div>
                    </div>
                    {plan.popular && (
                      <Badge
                        variant="secondary"
                        className="bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-100"
                      >
                        Popular
                      </Badge>
                    )}
                  </div>
                  <p className="text-gray-600 dark:text-gray-300 mt-4">
                    {plan.description}
                  </p>
                </CardHeader>
                <CardContent className="flex-grow relative z-10">
                  <ul className="space-y-2">
                    {plan.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-center">
                        <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <CardFooter className="relative z-10 mt-auto">
                  <Button
                    disabled={loading}
                    className={`w-full ${plan.popular ? 'bg-blue-600 hover:bg-blue-700' : ''}`}
                    onClick={() => {
                      setLoading(true)
                      selectProduct(plan)
                      setLoading(false)
                    }}
                  >
                    {plan.cta}
                  </Button>
                </CardFooter>
              </Card>
            </motion.div>
          ))}
        </div>
      </div>
      <p className="text-sm text-center mt-4">
        <span className="text-gray-600 dark:text-gray-300">
          * Local virtual receptionist numbers supported in the US, Canada, UK
          at any time through our self-service checkout. Several European
          countries also supported on a per-request basis, with a possible
          country specific surcharge for numbers. For other countries, get in
          touch with our team, because for regulation reasons, you&apos;ll need
          to prove your address before we can provide the service for you. Fair
          use policies apply to unlimited plans.
        </span>
      </p>
    </div>
  )
}
