interface GtagParams {
  page_path?: string
  event_category?: string
  event_label?: string
  plan_id?: string
  value?: string | number
  send_to?: string
  item_name?: string
  item_category?: string
  item_variant?: string
  currency?: string
  [key: string]: string | number | undefined
}

declare global {
  interface Window {
    gtag: (
      command: 'config' | 'event',
      actionOrId: string,
      params?: GtagParams
    ) => void
    dataLayer: any[]
  }
}

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID

// Initialize dataLayer array if it doesn't exist
if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || []
}

// Safely call gtag
function safeGtag(...args: Parameters<Window['gtag']>) {
  if (typeof window !== 'undefined' && window.gtag) {
    try {
      window.gtag(...args)
    } catch (error) {
      console.error('Error calling gtag:', error)
    }
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (!GA_TRACKING_ID) {
    console.warn('GA_TRACKING_ID is not defined')
    return
  }
  safeGtag('config', GA_TRACKING_ID, {
    page_path: url,
  })
}

// Updated to match Google Analytics implementation
export const gtag = (
  command: 'config' | 'event',
  actionOrId: string,
  params?: GtagParams
) => {
  if (typeof window === 'undefined') return
  if (!window.gtag) {
    console.warn('gtag is not available')
    return
  }
  safeGtag(command, actionOrId, params)
}
